import styled from "styled-components"

export const TestimonialsComment = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin: 0;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  position: relative;
  z-index: 1;

  @media (max-width: 1024px) {
    padding: 25px;
  }

  @media (max-width: 425px) {
    padding: 18px;
  }
`

export const CommentTitle = styled.p`
  margin-bottom: 30px;
  font-size: 18px;
  color: #fff;

  @media (max-width: 1300px) {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
`

export const TestimonialsProfile = styled.div`
  display: block;

  color: #fff;

  @media (max-width: 1300px) {
    margin-top: 18px;
  }
`

export const ProfileName = styled.p`
  font-size: 20px;
  font-weight: 300;
`

export const VerifiedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`

export const VerifiedReview = styled.span`
  color: #fff;
  margin-top: 8px;
  font-size: 17px;
`

export const TestimonialsCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`
