import React from "react"

import { StyledButton } from "./styled"

const Button = ({ children, onClick, alignSelf, variant = "white", style }) => {
  return (
    <StyledButton
      onClick={onClick}
      alignSelf={alignSelf}
      variant={variant}
      style={style}
    >
      {children}
    </StyledButton>
  )
}

export default Button
