import { Link } from "gatsby"
import styled from "styled-components"

export const FooterWrapper = styled.footer`
  ul {
    list-style: none;
    margin: 0;
  }

  li {
    margin: 0;
  }

  a {
    text-decoration: none;
  }
`

export const FooterTopWrapper = styled.div`
  background-color: #11100f;
  padding: 75px 0;
`

export const FooterTop = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 50px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`

export const FooterTopItem = styled.li`
  align-self: flex-start;
`

export const FooterTopLabel = styled.h6`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  margin: 0 0 25px 0;
`

export const FooterTopList = styled.ul``

export const FooterTopListItem = styled.li`
  :not(:last-child) {
    margin-bottom: 8px;
  }
`

export const FooterTopListLink = styled(Link)`
  color: #8c8c8c;
  transition: 0.1s ease;
  :hover {
    color: #fff;
  }
`

export const FooterBottomWrapper = styled.div`
  background-color: #11100f;
  padding: 20px 0;
  /* border-top: 1px solid rgba(255, 255, 255, 0.3); */
`

export const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
`

export const ContactLink = styled.a`
  color: #fff;
  font-size: 14px;
`

export const FooterCopy = styled.p`
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin: 0;
`

export const FooterSocials = styled.ul`
  display: inline-flex;
  align-items: center;
  gap: 20px;
`

export const FooterSocial = styled.li``

export const FooterSocialLink = styled.a`
  transition: 0.2s ease;
  display: flex;
  :hover {
    opacity: 0.7;
  }

  img {
    width: 25px;
    height: 25px;
  }
`
