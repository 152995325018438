import React from "react"

import { Container } from "../Layout/styled"

import {
  ContactLink,
  FooterBottom,
  FooterBottomWrapper,
  FooterCopy,
  FooterSocial,
  FooterSocialLink,
  FooterSocials,
  FooterWrapper,
} from "./styled"

const Footer = ({ data }) => {
  return (
    <FooterWrapper>
      <FooterBottomWrapper>
        <Container>
          <FooterBottom>
            <div>
              <FooterCopy>
                @ {new Date().getFullYear()} &nbsp;
                {data.copyrightText}
              </FooterCopy>

              <ContactLink href="mailto:contact@nuevacode.com">
                contact@nuevacode.com
              </ContactLink>
            </div>

            <FooterSocials>
              {data.socials.map(({ img: { file }, link, id }) => (
                <FooterSocial key={id}>
                  <FooterSocialLink href={link} target="_blank">
                    <img src={file.url} alt="Social" />
                  </FooterSocialLink>
                </FooterSocial>
              ))}
            </FooterSocials>
          </FooterBottom>
        </Container>
      </FooterBottomWrapper>
    </FooterWrapper>
  )
}

export default Footer