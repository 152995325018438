import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Footer from "../Footer"
import Header from "../Header"

import { Loader } from "components"
import { Main, Wrapper } from "./styled"

import "./reset.css"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        header: contentfulServices {
          serviceItems {
            img {
              file {
                url
              }
            }
            id
            slug
            title
            desc {
              desc
            }
          }
        }
        footer: contentfulFooter {
          copyrightText
          socials {
            img {
              file {
                url
              }
            }
            link
            id
          }
        }
      }
    `
  )
  if (typeof window === "undefined") return <Loader />

  return (
    <Wrapper>
      <Header location={location} data={data.header} />

      <Main>{children}</Main>

      <Footer data={data.footer} />
    </Wrapper>
  )
}

export default Layout