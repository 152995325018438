import React from "react"
import { Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import {
  CasesItemCategories,
  CasesItemContainer,
  CasesItemCustomer,
  CasesItemCustomerInfo,
  CasesItemCustomerName,
  CasesItemCustomerWrapper,
  CasesItemImage,
  CasesItemPreview,
  CasesItemTags,
  Image,
} from "./styled"

const CasesSlideItem = ({
  img,
  description,
  title,
  projectInfo: { technologies },
  slug,
}) => {
  return (
    <Link to={`/cases/${slug}`}>
      <CasesItemContainer>
        <CasesItemPreview>
          <CasesItemCategories>
            <CasesItemTags>
              {technologies &&
                technologies.map((technology, index) => (
                  <span key={index}>{technology}</span>
                ))}
            </CasesItemTags>
          </CasesItemCategories>

          <Image>
            <CasesItemImage image={getImage(img)} alt={title} />
          </Image>
        </CasesItemPreview>

        <CasesItemCustomer>
          <CasesItemCustomerWrapper>
            <CasesItemCustomerInfo>
              {title && <CasesItemCustomerName>{title}</CasesItemCustomerName>}
              {/* {description && <p>{description}</p>} */}
            </CasesItemCustomerInfo>
          </CasesItemCustomerWrapper>
        </CasesItemCustomer>
      </CasesItemContainer>
    </Link>
  )
}

export default CasesSlideItem
