import styled from "styled-components"
import { SectionTitle } from "styles"

export const WhyUsSection = styled.div`
  padding: 10vh 0 11vh 0;
  background-color: #11100f;
`

export const WhyUsTitle = styled(SectionTitle)`
  color: #fff;
  text-align: left;

  @media (max-width: 1300px) {
    font-size: 42px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
  }
`

export const WhyUsGrid = styled.div`
  margin-top: 70px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 110px;
  grid-row-gap: 76px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
    margin-top: 45px;
  }

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`

export const WhyUsItem = styled.div`
  max-width: 360px;
`

export const WhyUsImage = styled.img`
  width: 75px;
  height: 75px;
  background-color: #1e1e1e;
  border-radius: 10px;
  padding: 10px;

  @media (max-width: 1024px) {
    width: 65px;
    height: 65px;
  }
`

export const WhyUsItemTitle = styled.h5`
  color: #fff;
  font-size: 17px;
  line-height: 1.23;
  font-weight: 600;
  margin: 36px 0 10px 0;

  @media (max-width: 520px) {
    margin: 20px 0 10px 0;
  }
`

export const WhyUsItemText = styled.p`
  font-weight: 500;
  font-size: 17px;
  line-height: 1.3;
  color: #86868b;
`
