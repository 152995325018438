import React, { useRef, useState } from "react"
import emailjs from "@emailjs/browser"
import { Link } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import { Button } from "components"
import {
  ContactFormContainer,
  ContactFormInput,
  ContactFormLabel,
  ContactFormSubmit,
  ContactFormTextarea,
} from "./styled"

const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

const ContactForm = ({ width }) => {
  const breakpoints = useBreakpoint()
  const form = useRef()

  const [isSubmit, setIsSubmit] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isRegexpError, setIsRegexpError] = useState(true)

  const regexpCheck = value => {
    const regexpText = new RegExp(regexp)
    return regexpText.test(value)
  }

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_an7zyuj",
        "template_8l2zjoh",
        form.current,
        "w5oo0QSVFYEJZreb9"
      )
      .then(
        () => {
          setIsSubmit(true)
        },
        () => {
          setIsError(true)
        }
      )
  }

  const onChange = e => {
    const regexpIsValid = regexpCheck(e.target.value)
    setIsRegexpError(!regexpIsValid)
  }

  return isSubmit ? (
    <ContactFormSubmit>
      <h2>Thank You!</h2>

      <p>We will contact you shortly!</p>

      <Link to="/">
        <Button variant="dark">Go to Home Page</Button>
      </Link>
    </ContactFormSubmit>
  ) : isError ? (
    <ContactFormSubmit>
      <h2>Something went wrong!</h2>

      <p>Please fill form again</p>

      <Button onClick={() => setIsError(false)} variant="dark">
        Try again
      </Button>
    </ContactFormSubmit>
  ) : (
    <ContactFormContainer
      width={width}
      ref={form}
      onSubmit={sendEmail}
      isRegexpError={isRegexpError}
    >
      <ContactFormLabel>
        Name*
        <ContactFormInput type="text" name="name" />
      </ContactFormLabel>

      <ContactFormLabel>
        Email*
        <ContactFormInput
          required
          onChange={onChange}
          type="email"
          name="email"
        />
      </ContactFormLabel>

      <ContactFormLabel>
        Description
        <ContactFormTextarea name="message" />
      </ContactFormLabel>

      <Button
        type="submit"
        variant="dark"
        alignSelf={breakpoints.sm ? "center" : "auto"}
      >
        Submit
      </Button>
    </ContactFormContainer>
  )
}

export default ContactForm
