import React from "react"

import { FlexContainerWrapper } from "./styled"

const FlexContainer = ({
  children,
  display,
  gap,
  flexDirection,
  alignItems,
  width,
  ...rest
}) => {
  return (
    <FlexContainerWrapper
      display={display}
      gap={gap}
      flexDirection={flexDirection}
      alignItems={alignItems}
      width={width}
      {...rest}
    >
      {children}
    </FlexContainerWrapper>
  )
}

export default FlexContainer
