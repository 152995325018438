import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Seo = ({ description, title, children, ogUrl, imageUrl }) => {
  const { site, ogImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        ogImage: file(
          absolutePath: { glob: "**/src/images/metatag-image.png" }
        ) {
          publicURL
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const url = site.siteMetadata.siteUrl
  const image = imageUrl || `${url}${ogImage.publicURL}`
  const orUrlPath =
    ogUrl !== undefined ? `${url}${ogUrl}` : site.siteMetadata.siteUrl

  return (
    <>
      <title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="theme-color" content="#ffffff"></meta>
      <meta name="msapplication-TileColor" content="#ffffff"></meta>

      {/* Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={orUrlPath} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={orUrlPath} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta property="twitter:image" content={image} />
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``} />
      {children}
    </>
  )
}

export default Seo
