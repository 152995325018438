import React from "react"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css/navigation"

import FadeIn from "../fadeIn"
import { IndustriesHeader } from "../IndustriesNew/styled"

import { Container } from "components/Layout/styled"
import ArrowCases from "images/arrow-cases-slider-left.svg"
import TestimonialsSlideItem from "./components/TestimonialsSlideItem"
import {
  ButtonsWrapper,
  SliderBtn,
  SliderWrapper,
  TestimonialSectionTitle,
  TestimonialsSection,
} from "./styled"

import "swiper/css"

const TestimonialsNew = ({ data: { sectionTitle, testimonialsItems } }) => {
  const arrowSlide = ArrowCases
  const isDownMd = window.innerWidth < 1024
  const isUpSm = window.innerWidth >= 768
  // const isDownSm = window.innerWidth < 768
  const width = window.innerWidth

  const numberOfSlides = width => {
    if (width > 1300) return 3
    if (width > 1100) return 2
    if (width > 768) return 1

    return 1
  }

  const getCarouselOffset = (isMobile, width) => {
    if (width >= 1512) return 150
    if (width >= 1280) return 150

    return 0
  }

  const offset = getCarouselOffset(isDownMd, width)

  return (
    <TestimonialsSection>
      <Container>
        <FadeIn>
          <IndustriesHeader>
            <TestimonialSectionTitle>{sectionTitle}</TestimonialSectionTitle>
          </IndustriesHeader>
        </FadeIn>

        <FadeIn>
          <SliderWrapper>
            <Swiper
              navigation={{
                nextEl: ".next-btn-testimonials",
                prevEl: ".prev-btn-testimonials",
              }}
              modules={[Navigation]}
              className="mySwiper"
              spaceBetween={16}
              slidesPerView={numberOfSlides(window.innerWidth)}
              slidesOffsetBefore={offset}
              slidesOffsetAfter={isUpSm && offset + 400}
            >
              {testimonialsItems.map(testimonialsItem => {
                return (
                  <SwiperSlide
                    key={testimonialsItem.id}
                    style={{ marginBottom: "16px" }}
                  >
                    <TestimonialsSlideItem {...testimonialsItem} />
                  </SwiperSlide>
                )
              })}

              <ButtonsWrapper>
                <SliderBtn
                  className="prev-btn-testimonials"
                  style={{ left: 0 }}
                >
                  <img src={arrowSlide} alt="Previous Slide" />
                </SliderBtn>
                <SliderBtn
                  className="next-btn-testimonials"
                  style={{ right: 0 }}
                >
                  <img
                    src={arrowSlide}
                    alt="Next Slide"
                    style={{ transform: "rotate(3.142rad)" }}
                  />
                </SliderBtn>
              </ButtonsWrapper>
            </Swiper>
          </SliderWrapper>
        </FadeIn>
      </Container>
    </TestimonialsSection>
  )
}

export default TestimonialsNew
