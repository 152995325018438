import styled from "styled-components"

import PatternImageRight from "images/birds/06.svg"
import PatternImageLeft from "images/pattern-2.svg"

export const PromotionSection = styled.section`
  padding: 90px 0;
  background-color: #11100f;
  position: relative;
`

export const PatternBirdLeft = styled.div`
  height: auto;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${PatternImageLeft});
  background-repeat: no-repeat;
  background-position: top -120px left 0%;
  background-size: auto;
`

export const PatternBirdRight = styled.div`
  height: auto;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${PatternImageRight});
  background-repeat: no-repeat;
  background-position: bottom 0 right 10%;
  background-size: 100px;
`

export const PromotionTextSection = styled.div`
  display: flex;

  @media only screen and (min-width: 1004px) and (max-width: 1179px) {
    padding-left: 200px;
  }

  @media only screen and (min-width: 1180px) and (max-width: 1345px) {
    padding-left: 250px;
  }

  @media only screen and (min-width: 1346px) {
    padding-left: 314px;
  }

  & > * {
    color: #fff;
    font-size: 50px;
    font-weight: 200;
    letter-spacing: -0.04em;
    line-height: 1.1;
    max-width: 920px;

    @media (max-width: 1024px) {
      font-size: 46px;
    }

    @media (max-width: 768px) {
      font-size: 42px;
    }

    @media (max-width: 470px) {
      font-size: 36px;
    }
  }

  b {
    background-image: linear-gradient(156deg, #7d61c2, #c740a3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
