import React, { useState } from "react"
import { animated, useSpring } from "@react-spring/web"
import { graphql, useStaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { FadeIn } from "components"
import { Container } from "components/Layout/styled"
import {
  ServiceItem,
  ServicesContainer,
  ServicesDescription,
  ServicesItemTitle,
  ServicesSection,
  ServicesTitle,
  TabsContainer,
  ToggleTab,
} from "./styled"

const Services = ({ services, page }) => {
  const data = useStaticQuery(
    graphql`
      query {
        services: contentfulServices {
          id
          name
          sectionTitle
          btnText
          serviceItems {
            img {
              file {
                url
              }
            }
            id
            slug
            title
            desc {
              desc
            }
          }
          whyWeDoText {
            raw
          }
        }
      }
    `
  )

  const filteredServices = data.services.serviceItems.filter(
    item => item.slug !== services.slug
  )

  const renderToggleOrTitle = page => {
    switch (page) {
      case "home":
        return (
          <TabsContainer>
            {tabs.map((tab, index) => (
              <ToggleTab
                key={index}
                className={activeTab === index && "active"}
                onClick={() => handleClick(index)}
              >
                {tab}
              </ToggleTab>
            ))}
            <ToggleTab className="overlay" activeTab={activeTab} />
          </TabsContainer>
        )
      case "services":
        return <ServicesTitle>Other Services</ServicesTitle>
      default:
        break
    }
  }

  const [activeTab, setActiveTab] = useState(0)
  const tabs = ["What We Do", "Why We Do It"]
  const baseAnimation = {
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { tension: 280, friction: 120, duration: 1000 },
  }

  const [styles, api] = useSpring(() => baseAnimation, [])

  const handleClick = index => {
    api.start(baseAnimation)
    setActiveTab(index)
  }

  return (
    <ServicesSection>
      <Container>
        <FadeIn>
          <ServicesContainer>
            {renderToggleOrTitle(page)}
            {!activeTab && (
              <animated.div style={styles}>
                {filteredServices.map(({ title, slug, id }) => (
                  <ServiceItem key={id} to={`/services/${slug}`}>
                    <ServicesItemTitle>{title}</ServicesItemTitle>
                  </ServiceItem>
                ))}
              </animated.div>
            )}
            {activeTab > 0 && (
              <animated.div style={styles}>
                <ServicesDescription>
                  {renderRichText(data.services.whyWeDoText)}
                </ServicesDescription>
              </animated.div>
            )}
          </ServicesContainer>
        </FadeIn>
      </Container>
    </ServicesSection>
  )
}

export default Services
