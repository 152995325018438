import styled from "styled-components"
import { SectionTitle } from "styles"

export const TestimonialsSection = styled.div`
  padding: 90px 0 180px 0;
  overflow: hidden;
  background-color: #11100f;

  .swiper {
    position: relative;
    overflow: visible;
  }

  .swiper .swiper-slide {
    width: 450px;
    height: 450px;

    @media (max-width: 1024px) {
      width: 400px;
    }
    @media (max-width: 768px) {
      height: auto;
    }
  }

  @media (max-width: 768px) {
    padding: 40px 0 80px 0;
  }
`

export const TestimonialSectionTitle = styled(SectionTitle)`
  text-align: start;
  color: #fff;
  margin-bottom: 0px;

  @media (max-width: 1024px) {
    width: 70%;
  }
`

export const SliderWrapper = styled.div`
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 45%;

    font-weight: bold;
  }

  .swiper-pagination-bullet {
    width: 3px;
    height: 3px;

    background: #fff;
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 8px;
  }

  .swiper-slide-active {
    animation: shimmy 0.5s forwards;
    transform: translate(0, -40px);
  }

  .swiper-slide + .swiper-slide-active {
    animation: shimmy 0.5s forwards;
  }
  @keyframes shimmy {
    from {
      transform: translate(0, 0);
    }
    to {
      transform: translate(0, -40px);
    }
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 100;
  justify-content: space-between;
`

export const SliderBtn = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 0;

  border: 1px solid rgba(255, 255, 255, 0.3);
  background: 0 0;
  border-radius: 50%;
  cursor: pointer;
  overflow: visible;
  background-color: #292929;
  img {
    width: 7px;
    height: 11px;
  }

  &.swiper-button-disabled > img {
    opacity: 0.3;
    cursor: auto;
    pointer-events: none;
  }
`
