import React, { useEffect, useRef, useState } from "react"
import { animated, config, useSpring } from "react-spring"
import { useIntersectionObserver } from "usehooks-ts"

const FadeIn = ({ children }) => {
  const [hasAnimated, setHasAnimated] = useState(false)

  const animationRef = useRef(null)
  const entry = useIntersectionObserver(animationRef, {})
  const isVisible = !!entry?.isIntersecting
  const isMobile = window.innerWidth < 768

  const animation = useSpring({
    opacity: hasAnimated ? 1 : 0.25,
    transform: hasAnimated ? "translateY(0)" : "translateY(150px)",
    config: config.molasses,
  })

  useEffect(() => {
    if (isVisible && !hasAnimated) {
      setHasAnimated(true)
    }
  }, [hasAnimated, isVisible])

  useEffect(() => {
    if (isMobile) {
      setHasAnimated(true)
    }
  }, [isMobile])

  return (
    <animated.div ref={animationRef} style={animation}>
      {children}
    </animated.div>
  )
}

export default FadeIn
