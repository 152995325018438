import styled from "styled-components"

export const SectionSubTitle = styled.h2`
  font-size: 32px;
  text-align: center;
  margin: 0;
  padding: 0 14px;
  margin-bottom: 32px;
`
export const SectionTitle = styled.h2`
  font-size: 64px;
  text-align: center;
  margin: 0;
  margin-bottom: 70px;
  font-weight: 400;
  /* padding: 0 14px; */
  /* font-family: "Open Sans", sans-serif; */

  @media (max-width: 1300px) {
    font-size: 42px;
  }

  @media (max-width: 1024px) {
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
  }

  @media (max-width: 425px) {
    font-size: 27px;
  }
`

export const SectionDescription = styled.p`
  font-size: 21px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;

  @media (max-width: 1300px) {
    font-size: 19px;
  }

  @media (max-width: 425px) {
    font-size: 17px;
  }
`
