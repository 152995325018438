import styled from "styled-components"
import { SectionTitle } from "styles"

export const CasesSection = styled.section`
  padding: ${props => (props.isSection ? "5vh 0" : "10vh 0")};
  background-color: ${props =>
    props.isSection ? props.backgroundColor : "#11100f"};
  overflow: hidden;

  .swiper {
    overflow: visible;
    height: auto !important;
  }

  .swiper-slide {
    max-width: 520px;
    height: auto !important;

    @media (max-width: 1024px) {
      max-width: 480px;
    }

    @media (max-width: 768px) {
      max-width: 440px;
    }
  }

  .swiper-content {
    height: 100% !important;
  }
`

export const CasesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 70px;

  @media (max-width: 1024px) {
    margin-bottom: 30px;

    & > button {
      width: 180px;
      padding: 12px 24px;

      font-size: 17px;
    }
  }
`

export const CasesTitle = styled(SectionTitle)`
  margin-bottom: 0px;
  text-align: start;

  color: #fff;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

export const SliderBtn = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 0;

  border: 1px solid rgba(255, 255, 255, 0.3);
  background: 0 0;
  border-radius: 50%;
  cursor: pointer;
  overflow: visible;

  img {
    width: 7px;
    height: 11px;
  }

  &.swiper-button-disabled > img {
    opacity: 0.3;
    cursor: auto;
    pointer-events: none;
  }

  @media (max-width: 768px) {
    width: 42px;
    height: 42px;
  }
`
