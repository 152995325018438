import React from "react"
import { Navigation } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css/navigation"
import "swiper/css/mousewheel"
import "swiper/css/pagination"

import { Container } from "components/Layout/styled"
import ArrowCases from "images/arrow-cases-slider-left.svg"
import CasesSlideItem from "./CasesSlideItem"
import {
  ButtonsWrapper,
  CasesHeader,
  CasesSection,
  CasesTitle,
  SliderBtn,
} from "./styled"

import "swiper/css"

const Cases = ({ cases, backgroundColor, caseId }) => {
  const { title, casesList } = cases
  const arrowSlide = ArrowCases

  const filteredCasesList = casesList.filter(item => item.id !== caseId)
  const list = caseId ? filteredCasesList : casesList

  if (list.length === 0) {
    return null
  }

  return (
    <CasesSection backgroundColor={backgroundColor}>
      <Container>
        <CasesHeader
          data-sal="slide-up"
          data-sal-duration="600"
          data-sal-delay="50"
          data-sal-easing="ease"
        >
          <CasesTitle>{caseId ? `Other ${title}` : title}</CasesTitle>
          {list.length > 2 && (
            <ButtonsWrapper>
              <SliderBtn className="prev-btn-cases">
                <img src={arrowSlide} alt="Previous Slide" />
              </SliderBtn>
              <SliderBtn className="next-btn-cases">
                <img
                  src={arrowSlide}
                  alt="Next Slide"
                  style={{ transform: "rotate(3.142rad)" }}
                />
              </SliderBtn>
            </ButtonsWrapper>
          )}
        </CasesHeader>
        <Swiper
          navigation={{
            nextEl: ".next-btn-cases",
            prevEl: ".prev-btn-cases",
          }}
          modules={[Navigation]}
          spaceBetween={16}
          breakpoints={{
            320: {
              slidesPerView: 1.3,
            },
            // 768: {
            //   slidesPerView: 2,
            // },
          }}
          className="mySwiper"
          data-sal="slide-up"
          data-sal-duration="600"
          data-sal-delay="50"
          data-sal-easing="ease"
        >
          {list.map(casesList => (
            <SwiperSlide key={casesList.id}>
              <CasesSlideItem {...casesList} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </CasesSection>
  )
}

export default Cases
