import React from "react"

import Star from "images/star.svg"
import { StarsWrapper } from "./styled"

const FiveStar = () => {
  const star = Star

  return (
    <StarsWrapper style={{ display: "flex" }}>
      <div style={{ display: "flex" }}>
        <img src={star} alt="star" />
        <img src={star} alt="star" />
        <img src={star} alt="star" />
        <img src={star} alt="star" />
        <img src={star} alt="star" />
      </div>
    </StarsWrapper>
  )
}

export default FiveStar
