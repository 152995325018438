import React from "react"
import { Link } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import FiveStar from "../FiveStar"

import ClutchIcon from "images/clutch.svg"
import {
  CommentTitle,
  ProfileName,
  TestimonialsComment,
  TestimonialsCommentContainer,
  TestimonialsProfile,
  VerifiedReview,
  VerifiedWrapper,
} from "./styled"

const TestimonialsSlideItem = ({
  isSingle,
  reviewTitle,
  reviewerName,
  reviewerInfo,
  reviewText: { reviewText },
  link,
}) => {
  return (
    <TestimonialsComment>
      <FiveStar />
      <TestimonialsCommentContainer>
        <CommentTitle>&quot;{reviewText}&quot;</CommentTitle>

        <TestimonialsProfile>
          <ProfileName>{reviewerName || reviewerInfo}</ProfileName>
          {reviewerInfo && reviewerName && <p>{reviewerInfo}</p>}
        </TestimonialsProfile>
      </TestimonialsCommentContainer>

      <Link to={link} target="_blank">
        <VerifiedWrapper>
          <VerifiedReview>Verified on</VerifiedReview>
          <img src={ClutchIcon} alt="ClutchIcon" style={{ width: "70px" }} />
        </VerifiedWrapper>
      </Link>
    </TestimonialsComment>
  )
}

export default TestimonialsSlideItem
