import { ControlledMenu, MenuItem } from "@szhsin/react-menu"
import { menuSelector } from "@szhsin/react-menu/style-utils"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .burger {
    display: none;
    z-index: 6;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .burger .burger-lines,
  .burger .burger-lines:after,
  .burger .burger-lines:before {
    background-color: ${({ isOpen, isTop, isTransparent }) =>
      isOpen
        ? "#383838"
        : !isTop
        ? "#383838"
        : !isTransparent
        ? "#383838"
        : "#fff"};
  }
`

export const HeaderLogo = styled.img`
  max-width: 100%;
  width: 174px;
  margin: 0;
  z-index: 6;
  position: relative;

  @media (max-width: 425px) {
    width: 150px;
  }
`

export const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  gap: 2rem;

  & li {
    list-style: none;
    margin: 0;
    color: inherit;
    font-size: 1.2rem;
    position: relative;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    min-height: 100vh;
    z-index: 5;
    padding: 110px 14px 14px 14px;
    gap: 40px;
    transition: all 0.4s ease;

    & li {
      color: #383838;
      font-size: 30px;
    }

    transform: ${({ isOpen }) =>
      isOpen ? "translateX(0)" : "translateX(-100vw)"};
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  }
`

export const HeaderContainer = styled.header`
  position: fixed;
  width: 100%;
  z-index: 100;
  height: 90px;
  display: flex;
  align-items: center;
  transition: background 0.2s ease, top 0.4s ease 0.2s;
  background: ${props =>
    props.isBlack
      ? "#11100f"
      : props.isTop && props.isTransparent
      ? "transparent"
      : "rgba(73, 73, 73, 0.2)"};
  color: ${props => (props.isTop && props.isTransparent ? "#fff" : "#fff")};
  top: ${props => (props.isScrollDown ? "-90px" : 0)};

  backdrop-filter: ${props => (props.isTop ? "unset" : "blur(7px)")};
  & a.active {
    &::after {
      background: ${props =>
        props.isTop && props.isTransparent ? "#fff" : "#fff"};
    }
  }

  & a:hover {
    &::after {
      opacity: 1;
      background: ${props =>
        props.isTop && props.isTransparent ? "#fff" : "#fff"};

      @media (max-width: 768px) {
        display: none;
        opacity: 0;
      }
    }
  }

  svg path {
    stroke: ${props => (props.isTop && props.isTransparent ? "#fff" : "#fff")};

    @media (max-width: 768px) {
      stroke: ${({ isOpen }) => (isOpen ? "#383838" : "#fff")};
    }
  }
`

const baseLinkStyles = css`
  color: inherit;
  text-decoration: none;
  position: relative;
  font-weight: 400;
  margin-right: ${props => (props.services ? "14px" : "0")};

  &::after {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    right: 50%;
    transform: translate(50%, 0);
    opacity: 0;
    transition: all 0.3s ease;
  }
`

export const MenuArrow = styled.div`
  display: inline-flex;
  margin-left: 8px;

  svg {
    position: absolute;
    top: 40%;
    transition: transform 0.3s ease;
    transform: ${props =>
      props.open === "opening" || props.open === "open"
        ? "rotate(-180deg)"
        : "rotate(0)"};
  }
`

export const NavListLink = styled(Link)`
  ${baseLinkStyles}
  ${props =>
    props.arrow &&
    `
    display: flex;
    align-items: center;
    gap: 10px;
    img {
    width: 10px;
    }
    ::after {
      display: none;
    }
  `}

  &.active {
    &::after {
      opacity: 1;

      @media (max-width: 768px) {
        display: none;
        opacity: 0;
      }
    }
  }
`

export const ControlledMenuContainer = styled(ControlledMenu)`
  ${menuSelector.name} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    max-width: 700px;
    padding: 0;

    @media (max-width: 768px) {
      display: block;
      position: relative !important;
      box-shadow: none;
      top: unset !important;
      bottom: unset !important;
      padding: 20px 0 12px 0;
    }
  }
`

export const MenuItemContainer = styled(MenuItem)`
  border-radius: 0.25rem;
  padding: 25px 20px;
  align-items: flex-start;

  img {
    width: 50px;
    margin-right: 20px;
    align-self: flex-start;
  }

  @media (max-width: 768px) {
    padding: 0 0 25px 14px !important;

    :last-child {
      padding: 0 0 0 14px !important;
    }
  }
`

export const NavSubListLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  display: flex;

  @media (max-width: 768px) {
    align-items: center;
  }
`

export const MenuItemTitle = styled.h6`
  margin: 0 0 10px 0;
  line-height: 1.1;
  font-weight: 600;
  font-size: 15px;

  @media (max-width: 768px) {
    margin: 0;
    font-size: 22px;
    font-weight: 500;
  }
`

export const MenuItemDescription = styled.p`
  line-height: 1.35;
  font-size: 14px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const MenuItemImage = styled.img`
  flex: 0 0 50px;
`
