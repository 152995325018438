import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

export const CasesItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 520px;
  height: 100%;
  color: #fff;

  @media (max-width: 1024px) {
    max-width: 480px;
  }

  @media (max-width: 768px) {
    max-width: 440px;
  }
`

export const CasesItemPreview = styled.div`
  display: flex;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.3);
  user-select: none;
  border-radius: 12px;
  cursor: pointer;
  padding: 14px;

  @media (max-width: 475px) {
    height: 100%;
    justify-content: space-between;
  }
`
export const CasesItemCategories = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
export const CasesItemCountryWrapper = styled.div`
  display: flex;
  width: 30px;
  margin-right: 10px;
  padding-right: 13.5px;
  position: relative;
  overflow: hidden;

  &:after {
    position: absolute;
    content: "";
    top: 50%;
    right: 0;
    height: 9.5px;

    opacity: 0.2;
    border: 1px solid rgba(255, 255, 255, 0.3);
    transform: translateY(-50%);
  }
`

export const CasesItemTags = styled.div`
  display: inline-flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  span {
    margin-right: 10px;
    padding: 5px 8px;

    font-size: 10px;
    line-height: 1;

    background: hsla(240, 4%, 95%, 0.1);
    border-radius: 48px;
    color: #fff;

    @media (min-width: 1024px) {
      margin-right: 8px;
      padding: 6px 13px;

      font-size: 12px;
      line-height: 14px;
    }
  }
`

export const CasesItemNueva = styled.img`
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 16px;
  right: 16px;

  @media (min-width: 1024px) {
    width: 28px;
    height: 28px;
    top: 24px;
    right: 24px;
  }
`

export const Image = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;

  img {
    transition: transform 0.2s ease-in-out 0s !important;
  }

  &:hover img {
    transform: scale(1.1);
  }

  @media (max-width: 475px) {
    flex: inherit;
  }
`

export const CasesItemImage = styled(GatsbyImage)`
  display: block;
  pointer-events: none;
  border-radius: 12px;

  img {
    object-fit: cover;
    object-position: left;
  }
`

export const CasesItemCustomer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0 15px 0 14px;

  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  cursor: pointer;

  transition: background-color 0.3s ease;

  @media (min-width: 1024px) {
    padding: 0 22px 0 24px;

    border-radius: 12px;
  }

  @media (max-width: 442px) {
    min-height: 87px;
  }

  :hover {
    background-color: rgba(46, 47, 55, 0.8);
  }
`

export const CasesItemProfileLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  margin-right: 17.5px;
  padding-right: 15.5px;

  border-right: 1px solid hsla(240, 7%, 71%, 0.1);

  @media (min-width: 1024px) {
    margin-right: 22.5px;
    padding-right: 22.5px;
  }

  img {
    width: 58px;
  }
`

export const CasesItemCustomerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  position: relative;
  margin: 14px 0;

  @media (min-width: 1024px) {
    margin: 30px 0 24px;
  }
`

export const CasesItemCustomerInfo = styled.div`
  width: 95%;

  font-size: 12px;
  line-height: 19px;

  color: #b0b0bb;

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 23px;
  }

  p {
    white-space: pre-line;
  }
`

export const CasesItemCustomerName = styled.p`
  font-size: 16px;
  font-weight: 400;

  color: #fff;

  & + p {
    width: 60%;
  }

  @media (min-width: 1024px) {
    font-size: 22px;

    & + p {
      width: 80%;
    }
  }
`

export const Arrow = styled.div`
  content: "";
  position: absolute;
  right: 5px;
  bottom: 4px;

  @media (min-width: 1024px) {
    right: 3px;
    bottom: 4px;
  }

  img {
    width: 7px;
    height: 11px;

    opacity: 0.5;
  }
`

export const CasesItemRating = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 1px;
  right: 3px;

  font-size: 16px;
  line-height: 1;

  @media (min-width: 1024px) {
    top: -7px;
    right: 5px;

    font-size: 22px;
  }

  .icon-scroe {
    width: 18px;
    height: auto;
    margin-left: 4px;
    position: relative;
    top: 0px;

    @media (min-width: 1024px) {
      width: 23px;
      margin-left: 8px;
      top: 1px;
    }
  }

  .icon-apple {
    width: 13px;
    margin-left: 7px;
    position: relative;
    top: -1px;

    @media (min-width: 1024px) {
      width: 16px;
      margin-left: 10px;
      top: 0px;
    }
  }
`
