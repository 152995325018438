import styled from "styled-components"

export const ContactFormContainer = styled.form`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: ${props => props.width || "unset"};

  button {
    pointer-events: ${props => (props.isRegexpError ? "none" : "auto")};
    opacity: ${props => (props.isRegexpError ? "0.7" : "1")};
  }

  @media (max-width: 1024px) {
    button {
      font-size: 17px;
      padding: 12px 24px;
      width: 180px;
    }
  }

  @media (max-width: 768px) {
    gap: 15px;
  }

  input,
  textarea {
    padding: 10px;
    background: #f5f5f7;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: border-color 0.2s ease;
    font-size: 16px;

    &:focus {
      outline: none;
      border-color: #3921528a;
    }
  }
`

export const ContactFormSubmit = styled.div`
  border-radius: 5px;
  padding: 30px;
  text-align: center;
  align-self: center;
  flex: 1 1 auto;
  animation: show 1.5s;

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  h2 {
    margin: 0 0 15px 0;
    font-size: 36px;

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  p {
    font-size: 22px;
    margin: 0 0 35px 0;
  }

  button {
    min-width: 160px;
    padding: 11px 22px;
    font-size: 16px;
    text-transform: none;
  }
`

export const ContactFormLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const ContactFormInput = styled.input`
  min-height: 60px;

  @media (max-width: 768px) {
    min-height: 50px;
  }
`

export const ContactFormTextarea = styled.textarea`
  min-height: 144px;
  resize: none;
`
