import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { SectionTitle } from "styles"

export const IndustriesSection = styled.section`
  padding: 90px 0;
  background-color: #11100f;
`

export const IndustriesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;

  @media (max-width: 1024px) {
    & > button {
      font-size: 17px;
      padding: 12px 24px;
      width: 180px;
      margin-bottom: 30px;
    }
  }
`

export const IndustriesTitle = styled(SectionTitle)`
  text-align: start;

  color: #fff;
`

export const IndustriesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
`

export const IndustriesItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 38px 38px 31px;
  margin-bottom: 16px;

  background-color: transparent;
  border: 1px solid hsla(240, 7%, 71%, 0.3);
  border-radius: 12px;

  @media (min-width: 769px) {
    width: calc(50% - 8px);
  }
`

export const IndustriesItemImage = styled(GatsbyImage)`
  display: block;
  width: 100%;
  height: 202px;

  object-fit: cover;
  object-position: center;

  border: 1px solid hsla(240, 7%, 71%, 0.1);
  border-radius: 8px;
`

export const IndustriesItemWrapper = styled.div`
  width: 82%;
  margin: 0;

  line-height: 1.44;
`

export const IndustriesItemTitle = styled.h3`
  margin: 16px 0;

  font-size: 40px;
  color: #fff;

  @media (max-width: 1300px) {
    font-size: 36px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 425px) {
    font-size: 18px;
  }
`

export const IndustriesItemDescription = styled.p`
  margin-bottom: 22px;

  font-size: 16px;

  color: #bab9bc;
`

export const IndustriesItemButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 152px;

  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;

  color: #e100ff;
  text-shadow: rgb(240, 0, 255) 0px 0px 11px;
  background-color: transparent;
  border: 1px solid hsla(0, 0%, 100%, 0.24);
  border-radius: 48px;
  cursor: pointer;

  transition: 0.3s ease;
  transition-property: border-color, background-color;

  &:hover {
    background-color: rgba(93, 94, 100, 0.1);
    border-color: hsla(0, 0%, 100%, 0.1);
  }

  @media (max-width: 1024px) {
    width: 128px;

    font-size: 14px;
    line-height: 40px;
  }
`
