import React from "react"
import { Parallax, ParallaxProvider } from "react-scroll-parallax"

import FadeIn from "../fadeIn"

import { Container } from "components/Layout/styled"
import {
  PatternBirdLeft,
  PatternBirdRight,
  PromotionSection,
  PromotionTextSection,
} from "./styled"

const ParallaxWrapper = ({ children }) => {
  return (
    <ParallaxProvider>
      <Parallax
        translateY={[20, -70]}
        speed={15}
        style={{ position: "absolute", width: "100%", height: "100%" }}
      >
        {children}
      </Parallax>
    </ParallaxProvider>
  )
}

const PromotionText = ({ text }) => {
  return (
    <PromotionSection>
      <PatternBirdLeft />

      <PatternBirdRight />

      <Container>
        <FadeIn>
          <PromotionTextSection>{text}</PromotionTextSection>
        </FadeIn>
      </Container>
    </PromotionSection>
  )
}

export default PromotionText
