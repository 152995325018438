import styled from "styled-components"

export const StyledButton = styled.button`
  align-self: ${props => props.alignSelf || "auto"};
  border: 5em;
  cursor: pointer;
  outline: none;
  font-size: 19px;
  text-transform: uppercase;
  transform: translate(0);
  background-image: linear-gradient(45deg, #4568dc, #b06ab3);
  padding: 15px 22px;
  min-width: 180px;
  width: 200px;
  border-radius: 21px;
  box-shadow: ${props =>
    props.variant === "dark"
      ? "1px 1px 10px rgba(0, 0, 0, 0.55);"
      : "1px 1px 10px rgba(255, 255, 255, 0.438);"};
  transition: box-shadow 0.25s;
  color: white;
  &::after {
    content: "";
    border-radius: 18px;
    position: absolute;
    margin: 2px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: #0e0e10;
  }
  &:hover {
    background-image: linear-gradient(-45deg, #4568dc, #b06ab3);
    box-shadow: 0 12px 24px rgba(128, 128, 128, 0.1);
  }
`
