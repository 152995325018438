import React from "react"

import { LoaderWrapper, StyledLoader } from "./styled"

const Loader = () => {
  return (
    <LoaderWrapper>
      <StyledLoader>Loading</StyledLoader>
    </LoaderWrapper>
  )
}

export default Loader
