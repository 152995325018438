import React from "react"
import { navigate } from "gatsby"

import { FadeIn } from "components"
import { Container } from "components/Layout/styled"
import {
  IndustriesItem,
  IndustriesItemButton,
  IndustriesItemDescription,
  IndustriesItemTitle,
  IndustriesItemWrapper,
  IndustriesList,
  IndustriesSection,
  IndustriesTitle,
} from "./styled"

const IndestriesNew = ({ industry: { sectionTitle, industryItems } }) => {
  const handleContact = () => {
    navigate("/contact-us")
  }

  return (
    <>
      <IndustriesSection>
        <Container>
          <FadeIn>
            <IndustriesTitle>{sectionTitle}</IndustriesTitle>
          </FadeIn>

          <FadeIn>
            <IndustriesList>
              {industryItems.map(({ title, desc, id }) => {
                return (
                  <IndustriesItem key={id}>
                    <IndustriesItemWrapper id="IndustriesItemContainer">
                      <IndustriesItemTitle>{title}</IndustriesItemTitle>
                      <IndustriesItemDescription>
                        {desc?.desc}
                      </IndustriesItemDescription>
                      <IndustriesItemButton onClick={handleContact}>
                        Get in Touch!
                      </IndustriesItemButton>
                    </IndustriesItemWrapper>
                  </IndustriesItem>
                )
              })}
            </IndustriesList>
          </FadeIn>
        </Container>
      </IndustriesSection>
    </>
  )
}

export default IndestriesNew
