import { useEffect, useState } from "react"

export const useStickyState = (defaultValue, key) => {
  const isBrowser = () => {
    return typeof window !== "undefined"
  }

  const getValue = (key, defaultValue) => {
    return isBrowser() && window.localStorage.getItem(key)
      ? JSON.parse(window.localStorage.getItem(key))
      : defaultValue
  }

  const [value, setter] = useState(() => {
    return getValue(key, defaultValue)
  })

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setter]
}
