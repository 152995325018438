import React, { useEffect } from "react"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useStickyState } from "hooks/useStickyState"

import { Cookies, CookiesActions, CookiesDescription } from "./styled"

const CookiesBanner = () => {
  const isBrowser = () => {
    return typeof window !== "undefined"
  }

  const location = useLocation()

  useEffect(() => {
    if (isBrowser()) {
      initializeAndTrack(location)
    }
  }, [location])

  const [bannerHidden, setBannerHidden] = useStickyState(
    false,
    "consentCookieHidden"
  )

  const EnableAnalytics = () => {
    document.cookie = "gatsby-gdpr-google-analytics=true"
    setBannerHidden(true)
  }

  const handleCookiePolicy = () => {
    navigate("/cookie-policy")
  }

  return (
    <>
      {!bannerHidden && (
        <Cookies>
          <CookiesDescription>
            Our website uses cookies to personalize content and to analyze our
            traffic which may also result in profiling.
          </CookiesDescription>

          <CookiesActions>
            <button onClick={handleCookiePolicy}>Learn more</button>

            <button onClick={EnableAnalytics}>Got it</button>
          </CookiesActions>
        </Cookies>
      )}
    </>
  )
}

export default CookiesBanner
