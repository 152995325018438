import { Link } from "gatsby"
import styled from "styled-components"
import { SectionTitle } from "styles"

import Arrow from "images/services-arrow.svg"

export const ServicesSection = styled.section`
  padding: 90px 0;
  background-color: #11100f;
  overflow: hidden;
`

export const ServicesContainer = styled.div`
  padding-left: 0;

  @media only screen and (min-width: 1004px) and (max-width: 1179px) {
    padding-left: 200px;
  }

  @media only screen and (min-width: 1180px) and (max-width: 1345px) {
    padding-left: 250px;
  }

  @media only screen and (min-width: 1346px) {
    padding-left: 314px;
  }
`

export const ServicesTitle = styled(SectionTitle)`
  text-align: start;

  color: #fff;
`

export const TabsContainer = styled.ul`
  position: relative;
  margin-bottom: 48px;
  display: inline-flex;
  padding: 5px;

  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 100px;
`

export const ToggleTab = styled.li`
  position: relative;
  z-index: 2;
  min-width: 188px;
  margin: 0 4px 0 0;
  padding: 12px 6px;

  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: -0.03em;
  text-transform: uppercase;

  border-radius: 100px;
  cursor: pointer;

  &.active {
    color: rgb(10, 11, 12);

    transition: 0.5s;
  }

  &.overlay {
    position: absolute;
    width: 50%;
    max-width: 188px;
    height: 43px;
    top: 5px;
    left: ${({ activeTab }) => (activeTab ? "201px" : "5px")};
    z-index: 1;

    background-color: #fff;
    border-radius: 100px;

    transition: 0.5s;

    @media (max-width: 768px) {
      height: 40px;
      max-width: 160px;
      top: 4px;
      left: ${({ activeTab }) => (activeTab ? "174px" : "5px")};
    }
  }

  &:first-child {
    margin-right: 9px;
  }

  &:nth-last-child(2) {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    min-width: 160px;
    font-size: 16px;
    padding: 10px 4px;
    margin: 0 2px 0 0;
  }
`

export const ServiceItem = styled(Link)`
  position: relative;
  display: block;
  padding: 24px 50px 31px 0;

  line-height: 1.1;
  letter-spacing: -0.03em;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 48px;
    height: 48px;
    right: 0;
    top: 27px;

    background-image: url(${Arrow});
    background-repeat: no-repeat;
    background-size: 66px;
    background-position: center;
    opacity: 0.3;

    transform: rotate(-45deg);
    transition: all 0.35s ease-in-out;

    @media (max-width: 1024px) {
      top: 13px;
    }

    @media (max-width: 768px) {
      top: 11px;
    }

    @media (max-width: 425px) {
      width: 38px;
      height: 38px;
      top: 11px;
      background-size: 54px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -50vw;
    width: 200vw;
    height: 1px;

    background-color: #fff;
    opacity: 0.09;

    transition: all 0.3s ease-in-out;
  }

  div {
    &:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      left: -36px;
      top: 42%;

      background-color: #e100ff;
      border-radius: 100px;

      transform: scale(0);
      transition: all 0.35s ease-in-out;
    }
  }

  &:hover {
    div {
      ::before {
        transform: scale(1);
      }
    }

    ::before {
      opacity: 1;

      transform: rotate(0);
    }
  }

  @media (max-width: 1024px) {
    padding: 18px 50px 24px 0;
  }

  @media (max-width: 425px) {
    padding: 16px 50px 22px 0;
  }
`

export const ServicesItemTitle = styled.div`
  color: #fff;
  font-size: 36px;

  @media (max-width: 1024px) {
    font-size: 32px;
  }

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 425px) {
    font-size: 24px;
  }
`

export const ServicesDescription = styled.div`
  width: 90%;
  color: #fff;
  font-size: 38px;
  line-height: 1.3;
  letter-spacing: -0.02em;
  & > p {
    font-size: 30px;
    margin: 20px 0;
  }

  b {
    background-image: linear-gradient(156deg, #7d61c2, #c740a3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
