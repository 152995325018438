import styled from "styled-components"

export const LoaderWrapper = styled.div`
  position: relative;
  background-color: #000;
  z-index: -3;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledLoader = styled.div`
  width: 12vw;
  background-color: #1b2431;
  height: 12vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.8rem;
  color: white;
  font-weight: bold;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    top: -5%;
    left: -5%;
    height: 110%;
    width: 110%;
    border-radius: 50%;
    opacity: 0.5;
    filter: blur(60px);
    background-color: #ff0072;
    z-index: -2;
    animation: backglow1 3s ease infinite;
  }

  &::after {
    position: absolute;
    content: "";
    top: -5%;
    left: -5%;
    height: 110%;
    width: 110%;
    border-radius: 50%;
    opacity: 0.5;
    filter: blur(60px);
    background-color: #00ffff;
    z-index: -1;
    animation: backglow2 3s ease infinite;
  }

  @keyframes backglow1 {
    0% {
      transform: translate(10%, 10%) scale(1);
      opacity: 0.5;
    }

    25% {
      transform: translate(-10%, 10%) scale(0.8);
      opacity: 0.5;
    }

    50% {
      transform: translate(-10%, -10%) scale(1.2);
      opacity: 0.8;
    }

    75% {
      transform: translate(10%, -10%) scale(1.1);
      opacity: 0.2;
    }

    100% {
      transform: translate(10%, 10%) scale(1);
      opacity: 0.5;
    }
  }

  @keyframes backglow2 {
    0% {
      transform: translate(-10%, -10%) scale(1);
      opacity: 0.5;
    }

    25% {
      transform: translate(10%, -10%) scale(0.8);
      opacity: 0.5;
    }

    50% {
      transform: translate(10%, 10%) scale(1.2);
      opacity: 0.8;
    }

    75% {
      transform: translate(-10%, 10%) scale(1.1);
      opacity: 0.2;
    }

    100% {
      transform: translate(-10%, -10%) scale(1);
      opacity: 0.5;
    }
  }
`
