import styled from "styled-components"

export const FlexContainerWrapper = styled.div`
  display: ${props => props.display || "flex"};
  gap: ${props => props.gap || "0"};
  flex-direction: ${props => props.flexDirection || "row"};
  align-items: ${props => props.alignItems || "stretch"};
  justify-content: ${props => props.justifyContent || "flex-start"};
  width: ${props => props.width || "unset"};
`
