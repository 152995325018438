import styled from "styled-components"

export const Cookies = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: center;
  padding: 15px 30px;
  background-color: #131416;
  z-index: 100000000;
  box-shadow: 0 10px 40px rgb(0 0 0 / 10%);

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`

export const CookiesDescription = styled.p`
  color: #fff;
  text-align: left;
  line-height: 1.2;
  font-size: 14px;
`

export const CookiesActions = styled.div`
  display: flex;
  gap: 20px;

  button {
    cursor: pointer;
    white-space: nowrap;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    transition: opacity 0.2s ease;
    font-size: 16px;

    :last-child {
      color: #b259b3;
    }

    :hover {
      opacity: 0.8;
    }
  }
`
